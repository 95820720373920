import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Jf5L1B3ysBc">
    <SEO title="Honor Your Father and Mother - The Big 10" />
  </Layout>
)

export default SermonPost
